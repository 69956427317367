<template>
  <div class="container safe-area-padding-bottom">
    <div class="mode-list">
      <div class="mode-box">
        <div class="mode-title">方式1: 在游戏内扫描二维码</div>
        <div class="mode-content">
          <div class="model-help">进入月之底发行的Dota2游廊游戏，通过游戏结算后弹出的二维码绑定。</div>
        </div>
      </div>
      <van-divider :style="{ color: '#d4d7e5', borderColor: '#2c2d33', padding: '0', fontSize: '14px' }">OR</van-divider>
      <div class="mode-box">
        <div class="mode-title">方式2: 手动输入SteamID绑定</div>
        <div class="mode-content">
          <div class="model-help">登录Dota2游戏找到下图中的SteamID，手动输入后绑定。</div>
          <img src="/images/user/mode-2-step.jpg" />
          <div class="bind-form">
            <van-field center label-width="65" v-model="form.steamAccount" type="digit" label="SteamID" />
          </div>
          <van-button block class="blue-btn bind-account" color="var(--app-button-bg-color)" style="margin-top: 5px" @click="handleBindSteam"
            >绑定SteamID</van-button
          >
        </div>
      </div>
      <!-- <van-divider :style="{ color: '#d4d7e5', borderColor: '#2c2d33', padding: '0', fontSize: '14px' }">OR</van-divider> -->
      <!-- <div class="mode-box">
        <div class="mode-title">方式3: Steam在线认证</div>
        <div class="mode-content">
          <div class="model-help">登录Steam账号在线认证，因为Steam被禁，此方式需要加速器或代理工具。</div>
          <div class="bind-form" style="margin-top: 15px">
            <a href="/steam-user/authorize" class="blue-btn">绑定Steam账号</a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BindSteam',
}
</script>
<script setup>
import { Divider, Field, CellGroup, Button, Toast, Dialog } from 'vant'
import { reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { bindSteam } from '@/api/user'
import { redirectPage } from '@/utils/common'

const store = useStore()
const route = useRoute()
const router = useRouter()
const VanDivider = Divider
const VanField = Field
const VanCellGroup = CellGroup
const VanButton = Button

const form = reactive({
  steamAccount: '',
  project: '',
})

onMounted(() => {
  initData()
})

const initData = () => {
  store.dispatch('user/getInfo').then(res => {
    if (res.data.user.player_id) {
      Toast.success({
        message: '已绑定SteamID，即将自动返回',
        forbidClick: true,
        onClose: () => {
          redirectPage()
        },
      })
    }
  })

  if (route.query.project) form.project = route.query.project
  if (route.query.uid) form.steamAccount = route.query.uid
}

const handleBindSteam = () => {
  Dialog.confirm({
    title: '提示',
    message: '绑定SteamID后领取的福利、礼包、CDKEY只会发放到此SteamID，请再次确认SteamID无误？',
    confirmButtonText: '确认无误',
    cancelButtonText: '再检查下',
    theme: 'round-button',
  }).then(() => {
    bindSteam(form).then(res => {
      Toast.success({
        message: res.message,
        forbidClick: true,
        onClose: () => {
          redirectPage()
        },
      })
    })
  })
}
</script>
<script></script>

<style lang="scss" scoped>
.mode-list {
  width: 100vw;
  box-sizing: border-box;
  padding: 20px;
}

.mode-box {
  border-radius: 10px;
  width: 90vw;
  background: #232329;
  overflow: hidden;
}

.mode-title {
  padding: 5px 15px;
  background: #373a44;
  color: #d3d7e5;
  font-size: 14px;
}

.mode-content {
  padding: 25px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #b0b4c9;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.model-help {
  width: 100%;
  font-size: 13px;
  line-height: 18px;
}

.mode-content img {
  border-radius: 10px;
  margin: 15px 0;
  width: 100%;
}

.weui-loadmore {
  width: 80%;
}

.mode-content img.qrcode {
  width: 50%;
}

.weui-loadmore {
  margin: 15px auto;
}

.bind-form {
  width: 100%;
}
</style>
